import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Button from "../components/button"
import Seo from "../components/seo"
import InfiniteLoop from "../components/infinite-loop"
import "../main.css"
import Testimonial from "../components/testimonial"

export const query = graphql`
  query {
    allMdx {
      edges {
        node {
          body
          frontmatter {
            jobTitle
            name
            stars
            image {
              childImageSharp {
                gatsbyImageData(height: 65, width: 65)
              }
            }
            alt
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const testimonials = data.allMdx.edges.sort(
    (a, b) => a.node.body.length - b.node.body.length
  )
  return (
    <Layout>
      <Seo title="Life-tuning | Home" />
      <section className="banner">
        <StaticImage
          id="banner-picture"
          src="../images/profile-banner.png"
          loading="eager"
          width={480}
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Kenneth Cockheijt from Life-tuning"
          placeholder="blurred"
        />
        <div>
          <h1>
            3 months from now, you could be in a completely different space
            mentally, spiritually and financially.
          </h1>
          <Button to="/contact">Book a free discovery call</Button>
        </div>
      </section>
      <section>
        <div className="text-wrapper">
          <h2>What is your why?</h2>
          <StaticImage
            className="rotating-quote"
            src="../images/round-quote-white.png"
            width={380}
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Kenneth Cockheijt from Life-tuning"
            placeholder="blurred"
          />
          <p>
            <strong>Do you feel like you want more out of life?</strong> Do you
            want to live life to its fullest, to grow old and have no regrets?
            Yet at the same time you keep putting your dreams and goals aside
            because you feel stuck, powerless or insecure?
          </p>
          <p>
            We all do, at least once in our life, but that doesn’t define us.
            What really matters is how we choose to evolve in order to surpass
            those feelings.
          </p>
          <p>
            So many people go through life feeling lost, unaware of what
            actually makes them feel happy, aligned and free. And who could
            blame them? We were never taught that we could have it all: health,
            happiness, freedom, love, wealth. Yet there is so much more to life
            than what we allow ourselves to think.
          </p>
          <p>
            And whilst some might find their passion or their purpose by chance
            or by accident, not everyone does. The good news is, all it really
            takes is the willingness to understand who you are and what you
            truly want in life.
          </p>
          <p>
            We all have unique interests, experiences and ideals, the concept
            behind Life-Tuning is finding the perfect balance between everything
            that truly matters to you. My objective as a coach is to help you
            reach your full potential by figuring out your WHY.
          </p>
          <p>
            Why do you do what you do? Why are you “you”? What drives you? What
            motivates you? What fascinates you?
          </p>
          <p>
            My intention is to guide you by asking the right questions, devising
            the right strategies, pointing out your strengths and strengthening
            your weaknesses. Together we'll start creating the ideal blueprint
            to help you navigate towards your objectives.
          </p>
          <p>
            This blueprint will be adjusted throughout every session, and I will
            be by your side to support and coach you along the way. After all,
            who wouldn’t want somebody by their side to help them gain new
            insights, keep them accountable and help them stay on track?
          </p>
          <p>
            We'll work as a team, I'll be there to keep you on track whilst you
            take responsibility and provide the effort and energy to succeed.
          </p>
          <p>
            With the both of us doing our part, results are guaranteed! So, are
            you ready to take charge of your life and become the person you want
            to be?
          </p>
          <Button accent={true} to="/contact">
            Book a free discovery call
          </Button>
        </div>
      </section>
      <InfiniteLoop loop={4}>
        Everything you need is already inside of you
      </InfiniteLoop>
      <section className="section-last">
        <h2 className="title-center">What people say about Life-Tuning</h2>
        <div className="testimonials-row">
          <div className="testimonials-column">
            {testimonials.map(({ node }, i) =>
              i % 2 === 0 ? <Testimonial key={i} node={node} /> : ""
            )}
          </div>
          <div className="testimonials-column">
            {testimonials.map(({ node }, i) =>
              i % 2 !== 0 ? <Testimonial key={i} node={node} /> : ""
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
