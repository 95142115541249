import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./css/testimonial.css"
import PropTypes from "prop-types"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Testimonial = ({ node: { frontmatter, body } }) => {
  const image = getImage(frontmatter.image)
  return (
    <article className="testimonial">
      <p className="stars">
        {[...Array(frontmatter.stars)].map((e, i) => (
          <span key={i} className="star" />
        ))}
      </p>
      <MDXRenderer>{body}</MDXRenderer>
      <div className="testimonial-info">
        <div className="testimonial-image">
          <GatsbyImage image={image} alt={frontmatter.alt} />
        </div>
        <div className="personal-info">
          <p className="name">{frontmatter.name}</p>
          <p className="job-title">{frontmatter.jobTitle}</p>
        </div>
      </div>
    </article>
  )
}

Testimonial.propTypes = {
  node: PropTypes.object.isRequired,
}

export default Testimonial
