import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "./css/button.css"

const Button = ({ children, to, accent, style, mt }) => {
  return (
    <Link
      style={style}
      className={`button ${accent === true ? "button-accent" : ""} ${
        mt === true ? "button--top" : ""
      }`}
      to={to}
    >
      {children}
    </Link>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  accent: PropTypes.bool,
  mt: PropTypes.bool,
}

export default Button
